import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import parse from 'html-react-parser'

import '../styles/templates/ServicesPage.scss'

import { map as _map } from 'lodash'

// Components
import { TitleDefault } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'

// Styles
import '../styles/templates/Downloads.scss'

// Images
import downloadIcon from 'img/download-icon.svg'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        image_downloads {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
          bestand {
            file {
              localFile {
                publicURL
                extension
              }
            }
            file_title
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

function DownloadsPage({ data: { page }, pageContext: { breadcrumb: { crumbs },}, }) {
  return (
    <Layout>
      {page.acf.image_downloads &&
      page.acf.image_downloads.localFile ? (
        <SEO
          yoast={{ meta: page.yoast_meta }}
          pathname={page.path}
          image={
            page.acf.image_downloads.localFile.childImageSharp.fixed
              .src
          }
          article
        />
      ) : (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} />
      )}
      <div className="downloads-template">
        <section className="heroimage position-relative d-flex">
          <div className="container context">
            <div className="row py-4">
              <div className="col-lg-6">
                <TitleDefault className="mb-3 color-text-light">
                  <span>{parse(page.title)}</span>
                </TitleDefault>
                <div className="line mb-4" />
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=">"
                  crumbLabel={page.title}
                />
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
          <div className="heroimage-image row position-absolute">
            <div className="col-12 col-lg-5 fill" />
            <div className="heroimage-image-container col-lg-7 p-0">
              <BackgroundImage
                className="bgimage"
                fluid={page.acf.image_downloads.localFile.childImageSharp.fluid}
              />
            </div>
          </div>
        </section>
        <section className="downloads-section py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 d-none d-lg-block" />
              <div className="col-lg-8 pb-3">
                {_map(page.acf.bestand, (file, index) => (
                  <div key={index} className="col mb-4">
                    <a href={file.file.localFile.publicURL} className="box" target="_blank" rel="noopener noreferrer">
                      <span className="file-type">{file.file.localFile.extension}</span>
                      {file.file_title}
                      <img src={downloadIcon} width="20" alt="" />
                    </a>
                  </div>
                ))}
              </div>
              <div className="col-lg-2 d-none d-lg-block" />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default DownloadsPage
